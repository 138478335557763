import React, { useState, useEffect, useRef } from 'react'

import {
  WrapBanner,
  Content,
  Title,
  Text,
  Button,
  BannerTextBlock,
  BannerImageBlock,
  BannerImage1,
  BannerImage2,
  BannerImage3
} from './styles'
import Img from 'gatsby-image'

import bannerImg1 from '../../../assets/images/RCM.png'

const Banner = ({}) => {
  const handleClick = (e) => {
    e.preventDefault()
    window.location = 'http://rentlio.com/en/pricing'
  }
  return (
    <WrapBanner>
      <Content>
        <BannerTextBlock>
          <Title>What is a Hotel Channel Manager?</Title>
          <Text>
            Discover what is a hotel channel manager and the benefits of an integrated channel manager and PMS.
          </Text>
          <Button onClick={(e) => handleClick(e)}>Get a Custom Pricing</Button>
        </BannerTextBlock>
        <BannerImageBlock>
          <img src={bannerImg1} />
        </BannerImageBlock>
      </Content>
    </WrapBanner>
  )
}

export default Banner
