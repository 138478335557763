import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

export const WrapHMS = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center; */
  box-sizing: border-box;
  max-width: 1860px;
  min-width: 1200px;
  width: 100%;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 1000px) {
    min-width: unset;
    width: 100vw;
  }
  @media only screen and (max-width: 425px) {
  }
`

import Layout from 'components/Layout'
import SEO from 'components/SEO'
import Header from 'components/Header'
import Footer from 'components/Footer'

import Logo from 'components/Logo'
import Button from 'components/Button'

import NotFoundImg from 'images/not-found-404.png'

import FAQauthority from '../../components/hotel-property-management-system/FAQauthority'
import GetStartedCta from '../../components/GetStartedCTA'
import Banner from '../../components/hotel-channel-manager-en/banner'
import Content from '../../components/hotel-channel-manager-en/content'
import schema from '../../components/hotel-channel-manager-en/schema.json'
const faqData = [
  {
    content:
      'A hotel channel manager is a software solution that enables hoteliers to manage their room inventory and rates across multiple online distribution channels such as online travel agencies (OTAs), metasearch engines, and the hotels own website.',
    title: 'What is a hotel channel manager?'
  },
  {
    content:
      'The cost of a channel manager can vary widely depending on several factors, including the number of channels you want to manage, the size of your property, and the features and functionality you need. Get custom pricing.',
    title: 'How much does a hotel channel manager cost?'
  }
]
const cta_mobile_apps = {
  title: 'READY TO GET STARTED?',
  description:
    "Create your account and invite your team. You'll save time and earn more. Give us a try; you won't regret it!"
}

const HPMS = ({ location }) => {
  return (
    <Layout locale="en">
      <SEO
        title="#1 Hotel Channel Manager"
        description="Discover what is a hotel channel manager and the benefits of having an integrated property management system and a hotel channel manager."
        pathname={location.pathname}
        lang="en"
        location={location}
        schemaMarkup={schema}
      />
      <Header mode="dark" locale="en" color={'#FFFFFF'} opacity={0.3} location={location} hasTranslation="false" />
      <WrapHMS>
        <Banner />
        <Content locale="en" />
      </WrapHMS>
      <FAQauthority questions={faqData} title="FAQ" authority />
      <GetStartedCta data={cta_mobile_apps} locale="en" location={location} />

      <Footer locale="en" location={location} />
    </Layout>
  )
}

export default HPMS
