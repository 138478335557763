import React, { useState, useEffect, useRef } from 'react'

import {
  WrapContent,
  MiddleTextContent,
  IntroText,
  GreyBlock,
  H2,
  H3,
  P,
  PUL,
  PMSsustavIMG,
  FullWidthImg,
  ImgTitle,
  MiddleContent,
  GradientText,
  SideText,
  ButtonCTA,
  UnderButtonText,
  LeftColumn,
  TopText,
  LeftLink,
  BlueLine,
  WrapLeftContent
} from './styles'
import Img from 'gatsby-image'

import HMSchannelManager from '../../../assets/images/HMSchannelManager.png'
import bookingengine from '../../../assets/images/booking-engine-illustration-hero.jpg'
import HBEanalitika from '../../../assets/images/HBEanalitika.png'
import PMSsustav from '../../../assets/images/PMSsustav.png'
import Kokpit from '../../../assets/images/MainDashboard.png'
import Kalendar from '../../../assets/images/Kalendar.png'
import Statistika from '../../../assets/images/Statistika.png'
import MobileApps from '../../../assets/images/MobileApps.png'
import RadarGroup from '../../../assets/images/RadarGroup.png'
import BookingAndReservations from '../../../assets/images/b&r.png'
import ReportingDashboard from '../../../assets/images/reportingDashboard.png'
import Biling from '../../../assets/images/biling.png'
import Integrations from '../../../assets/images/integrationsAuthority.png'
import Form from '../../AuthorityForm'

const links = [
  {
    id: 1,
    title: 'Benefits of Utilizing a Hotel Booking Engine'
  },
  {
    id: 2,
    title: 'Key Features of a Hotel Booking Engine'
  },
  {
    id: 3,
    title: 'Importance of Integrated Hotel Booking Engine and PMS'
  },
  {
    id: 4,
    title: 'What is Rentlio Hotel Booking Engine?'
  }
]

const Content = ({ locale }) => {
  useEffect(() => {
    var links = document.querySelectorAll('.link')
    var sections = document.querySelectorAll('.sections')
    window.onscroll = function () {
      var current = 'home'
      sections.forEach((section) => {
        const sectionTop = section.offsetTop
        if (pageYOffset >= sectionTop - 200) {
          current = section.getAttribute('id')
        }
      })
      links.forEach((item) => {
        item.classList.remove('active')
        if (item.href.slice(-1) === current) {
          item.classList.add('active')
        } else {
          item.classList.remove('active')
        }
      })
    }
  }, [])
  const handleClick = (e) => {
    e.preventDefault()
    window.location = 'https://app.rentl.io/en/sign-up'
  }

  return (
    <WrapContent>
      <LeftColumn>
        <WrapLeftContent>
          <BlueLine />
          <TopText>In-page topics </TopText>
          {links.map((link) => (
            <LeftLink key={link.id}>
              <a className="link" href={`#${link.id}`}>
                {link.title}
              </a>
            </LeftLink>
          ))}
        </WrapLeftContent>
      </LeftColumn>
      <MiddleTextContent>
        <P>
          A hotel channel manager is a software solution that enables hoteliers to manage their room inventory and rates
          across multiple OTA channels such as Boking.com, Expedia, Airbnb, and other marketplaces. An integrated
          channel manager and hotel's property management system (PMS), allows hoteliers to update their room rates and
          availability in real-time.
        </P>
        <H2 id="1" className="sections">
          How Does a Hotel Channel Manager Work?
        </H2>
        <P>
          A hotel channel manager works by integrating with the hotel's property management system (PMS) and connecting
          to various online distribution channels.{' '}
          <a href="https://rentl.io/en/hotel-property-management-system">Property Management System</a> is the central
          system that manages all hotel operations, such as room reservations, housekeeping, and billing. The channel
          manager integrates with the PMS to access the room inventory and rates, which it then updates across all the
          connected channels.
        </P>
        <P>
          When a guest book a reservation on one of the connected channels, the channel manager updates the inventory in
          real-time, ensuring that the room is no longer available on the other channels. This prevents overbooking and
          double bookings, which can cause problems for the hotel and the guest.
        </P>
        <P>
          Hotel Channel Manager also allows hoteliers to set different rates and restrictions for each channel,
          depending on the commission rates and other factors. This helps hotels to optimize their distribution strategy
          and maximize their revenue by targeting different customer segments and channels.
        </P>
        <FullWidthImg>
          <img src={HMSchannelManager} />
        </FullWidthImg>
        <ImgTitle>Rentlio Channel Manager</ImgTitle>
        <GreyBlock>
          <H2 id="2" className="sections">
            Benefits of Using a Hotel Channel Manager
          </H2>
          <P>There are several benefits of using a hotel channel manager, which we will discuss in detail below:</P>
          <H3>Saves Time and Effort</H3>
          <P>
            A hotel channel manager saves hoteliers a lot of time and effort by automating the distribution process.
            Instead of manually updating inventory and rates on each channel separately, the channel manager updates
            everything in real time across all channels. This eliminates errors and saves hoteliers a lot of time, which
            they can use to focus on other important aspects of their business.
          </P>
          <H3>Prevents Overbooking and Double Bookings</H3>
          <P>
            Overbooking and double bookings are some of the most common problems hotels face when managing their online
            distribution. With a channel manager, these problems are eliminated because the inventory is updated in real
            time across all channels. This ensures that the room is no longer available on the other channels when a
            guest makes a reservation, preventing overbooking and double bookings.
          </P>
          <H3>Increases Visibility and Bookings</H3>
          <P>
            A hotel channel manager increases the hotel's visibility and bookings by connecting to multiple online
            distribution channels. This allows the hotel to reach a wider audience and target different customer
            segments. The channel manager also allows hotels to set different rates and restrictions for each channel,
            which can help them to optimize their distribution strategy and maximize their revenue.
          </P>
          <H3>Improves Revenue Management</H3>
          <P>
            A hotel channel manager provides valuable insights into the hotel's performance across different channels.
            It allows hoteliers to track reservations, analyze performance, and make analytical decisions to improve
            their revenue management strategy. The channel manager also allows hotels to set dynamic pricing based on
            demand and availability, which can help them to maximize their revenue.
          </P>
          <H3>Enhances Guest Experience</H3>
          <P>
            A hotel channel manager enhances the guest experience by providing accurate and up-to-date room availability
            and rates information. This allows guests to make informed decisions and book the room that best suits their
            needs. The channel manager also allows hotels to communicate with guests in real-time.
          </P>
          <FullWidthImg>
            <img src={bookingengine} />
          </FullWidthImg>
          <ImgTitle>Rentlio Channel Manager</ImgTitle>
        </GreyBlock>
        <H2 id="3" className="sections">
          Channel Manager and PMS (property management system) Integration
        </H2>
        <P>
          Rentlio's Channel Manager Integration seamlessly combines your channel manager with various systems to enhance
          efficiency and effectiveness in managing your property. Integration is crucial for maintaining accuracy and
          ensuring the smooth operation of your property's backend. Here's a detailed look at the systems that can work
          in harmony with a channel manager to elevate business outcomes:
        </P>
        <H3>Property Management System (PMS):</H3>
        <P>
          Your PMS handles all aspects of hotel administration, from reservation management and check-in/out to guest
          profiles, housekeeping, maintenance, and availability/occupancy tracking.
        </P>
        <P>
          Integration with a channel manager establishes two-way communication. The channel manager instantly recognizes
          any changes in the PMS, triggering updates across all third-party booking sites and direct channels. This
          prevents the need for redundant data entry on different systems.
        </P>
        <H3>Hotel Booking Engine:</H3>
        <P>
          An online booking engine simplifies the process of accepting online reservations directly from your website or
          social media pages. It includes a crucial 'book now' button for guests to initiate their reservations.
        </P>
        <P>
          When paired with a channel manager, the booking engine is treated similarly to third-party sites. This enables
          easy performance tracking, rate updates, and maintenance of accurate availability.
        </P>
        <H3>Business Intelligence Tool:</H3>
        <P>
          Utilize hotel business intelligence software to optimize rates and maximize revenue. The tool tracks
          competitors and market changes based on supply and demand.
        </P>
        <P>
          Integration with a channel manager is valuable because it ensures your rates align with your competitors'. The
          software allows you to set alerts, optimize prices for increased revenue, or reduce them to secure bookings.
          Real-time market data is essential for long-range forecasting and determining when and at what level to set
          your rates.
        </P>
        <P>
          In summary, Rentlio's Channel Manager Integration facilitates a seamless flow of information between your
          property management system, booking engine, and business intelligence tool. This interconnected ecosystem
          enhances operational efficiency and ensures that rates and availability are always up-to-date across all
          channels.
        </P>
        <Form locale="en" />
        <GreyBlock>
          <H2 id="3" className="sections">
            Why is it Important to Have Integrated PMS, Channel Manager, and Booking Engine?
          </H2>
          <P>
            Having an integrated Property Management System (PMS), Channel Manager, and Booking Engine is crucial for a
            hotel's optimal functioning, and platforms like Rentlio play a pivotal role in streamlining operations in
            the hospitality industry.
          </P>
          <H3>Efficiency and Accuracy:</H3>
          <P>
            Integration ensures seamless communication between the PMS, Channel Manager, and Booking Engine. With
            Rentlio's integrated solution, changes made in the PMS are instantly reflected in the Channel Manager and
            Booking Engine. This eliminates manual data entry and reduces the risk of errors by ensuring that
            information is always up-to-date.
          </P>
          <H3>Real-time Updates:</H3>
          <P>
            A synchronized system provides real-time updates on room availability, rates, and reservations. This is
            particularly important in a dynamic and competitive industry where prices and availability change
            frequently. Rentlio's integration ensures that your hotel's online presence is always current, offering a
            consistent and accurate representation to potential guests.
          </P>
          <H3>Maximized Revenue:</H3>
          <P>
            The integration allows for dynamic rate management. Rentlio, in conjunction with a Channel Manager and
            Booking Engine, enables hoteliers to easily adjust prices based on market demand, competitor rates, and
            other factors. This dynamic pricing strategy maximizes revenue by optimizing room rates for different
            channels and periods.
          </P>
          <H3>Enhanced Guest Experience:</H3>
          <P>
            A seamlessly integrated system enhances the overall guest experience. Guests can make reservations easily
            through the Booking Engine, and their information is efficiently managed through the PMS. This integration
            ensures a smooth check-in and check-out process, as all relevant data is readily available to hotel staff.
          </P>
          <H3>Centralized Control:</H3>
          <P>
            Rentlio's integrated solution provides centralized control over various aspects of hotel management.
            Hoteliers can oversee reservations, room inventory, and pricing strategies from a single platform. This
            centralized control simplifies decision-making and allows for a more strategic approach to managing the
            hotel's online presence.
          </P>
          <H3>Competitive Advantage:</H3>
          <P>
            Hotels that leverage integrated systems gain a competitive advantage. They can respond quickly to demand
            changes, adapt pricing, and provide an exceptional booking experience for guests. Rentlio's integrated
            solution empowers hotels to stay ahead in a dynamic and competitive market.
          </P>
          <P>
            In conclusion, integrating a PMS, Channel Manager, and Booking Engine is paramount in the hotel industry for
            efficient, accurate, and revenue-maximizing operations. Rentlio's role in this ecosystem is significant,
            offering a comprehensive solution that enhances hotels' overall efficiency and competitiveness in today's
            digital landscape.
          </P>
        </GreyBlock>
        <H2>Discover Rentlio Channel Manager</H2>
        <P>
          <a href="https://rentl.io/en/channel-manager">Rentlio’s integrated PMS and channel manager</a> enables
          property owners to update their availability and rates in real-time, ensuring that their property is always
          listed as available on the various OTAs they have connected to. This eliminates the risk of overbooking and
          ensures that the property is always up-to-date across all channels.
        </P>
        <P>
          With <a href="https://rentl.io/en/channel-manager">Rentlio's channel manager</a>, property owners can also
          manage their reservations and bookings from a centralized dashboard, making it easier to keep track of their
          occupancy and revenue. Additionally, Rentlio offers advanced reporting and analytics features that provide
          insights into booking patterns, occupancy rates, and revenue trends, helping property owners to optimize their
          pricing and marketing strategies.
        </P>
        <P>
          Overall, Rentlio's channel manager is a powerful tool that can help property owners to increase their
          visibility and bookings across multiple channels, while streamlining their operations and maximizing their
          revenue potential.
        </P>
        <ButtonCTA onClick={(e) => handleClick(e)}>Get Free Consultations</ButtonCTA>
        {/* <UnderButtonText>Počnite koristiti svoj budući PMS, već danas!</UnderButtonText> */}
      </MiddleTextContent>
    </WrapContent>
  )
}

export default Content
